*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Buttons */
.fj-btn,
.fj-btn-outline,
.fj-btn-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding: 10.5px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    transition: 300ms ease-in;
    cursor: pointer;
}

.fj-btn {
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #FA4094;
    border: none;
}
.fj-btn.fj-btn-destructive {
    background-color: #B91C1C;
}

.fj-btn:focus {
    background-color: #FA4094;
    color: #FFF;
}

.fj-btn:hover,
.fj-btn:active {
    background-color: #DE116E;
    color: #FFF;
    text-decoration: none;
}

.fj-btn-outline {
    border: 1px solid #FA4094;
    color: #FA4094;
    background-color: #FFF;
    border-radius: 4px;
}

.fj-btn-text {
    border: none;
    color: #FA4094;
    background-color: transparent;
}

.fj-btn-outline:hover,
.fj-btn-outline:active,
.fj-btn-text:hover,
.fj-btn-text:active {
    background-color: #FFEEF6;
    color: #FA4094;
}

.fj-btn:disabled,
.fj-btn-outline:disabled,
.fj-btn-text:disabled,
.fj-btn[disabled],
.fj-btn-outline[disabled],
.fj-btn-text[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
}

.fj-btn-lg {
    padding: 14px 37px;
}

.fj-btn-md {
    padding: 10px 37px;
}

.fj-btn-sm {
    padding: 6px 37px;
}

.fj-btn .icon,
.fj-btn-outline .icon,
.fj-btn-text .icon {
    height: 20px;
    width: 20px;
}

/* Status Toggle */
.status-on {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-on input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.status-on label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 24px;
    background: #BBBBBB;
    display: block;
    border-radius: 100px;
    position: relative;
}

.status-on label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 5px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.status-on input:checked+label {
    background: #FA4094;
}

.status-on input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.status-on label:active:after {
    width: 18px;
}

.status-on input:disabled+label {
    cursor: not-allowed;
    opacity: 0.5;
}

.status-on span {
    margin-bottom: 5px;
    margin-right: 15px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
}

/* Input search */
.fj-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFF;
    padding: 8px 10px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    height: 40px;
}

.fj-search svg{
    margin-left: 8px;
}

.fj-search-input {
    background-color: #FFF;
    border: none;
    width: 100%;
}

.fj-search-input:focus {
    outline: none;
}

.fj-search:focus-within {
    outline: #66AFE9;
    border-color: #66AFE9;
}

.fj-search-wrapper {
    position: relative;
}

/* General Style */
.fj-w-full {
    width: 100%;
}

.fj-divider {
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
}

/* Checkbox */
.fj-checkbox-label {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #18181B;
    margin: 0;
}

.fj-checkbox {
    height: 20px;
    width: 20px;
    appearance: auto;
    accent-color: #FA4094;
}

/* Input */
.fj-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.fj-input-label {
    color: #18181B;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    margin: 0px;
    margin-bottom: 5px;
    border: none;
}

.fj-input {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 8px 16px;
}

/* Dropdown */
.fj-dropdown-wrapper {
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.jobs-sort-by.fj-dropdown-wrapper {
    cursor: default;
}

.fj-dropdown {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 8px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.fj-dropdown-svg {
    position: absolute;
    right: 10px;
    top: 10px;
    pointer-events: none;
}

.fj-drodowns {
    width: 100%;
}

.fj-drodown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #D4D4D4;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.fj-dropdown-menu {
    position: absolute;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 400px!important;
    max-height: 200px;
    height: 150px;
    overflow-y: scroll;
    bottom: -43px;
}

.fj-dropdown-menu-open {
    display: flex;
}

.fj-dropdown-menu-open {
    display: flex;
}

.fj-dropdown-menu-item,
.fj-dropdown-menu-placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    width: 100%;
}

.fj-dropdown-menu-placeholder {
    color: #A1A1AA;
}

.fj-dropdown-menu-item {
    cursor: pointer;
}

.fj-dropdown-menu-item:hover {
    background-color: #F5F5F5;
}

.fj-dropdown-menu-item svg {
    display: none;
}

.fj-dropdown-item-active {
    font-weight: 700;
}

.fj-dropdown-item-active svg {
    display: inline-block   ;
}
